import React from "react";
import ProfilePic from "../Assets/john-doe-image.png";
import { AiFillStar } from "react-icons/ai";

const Testimonial = () => {
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Testimonial</p>
        <h1 className="primary-heading">What They Are Saying</h1>
        <p className="primary-text">
          I absolutely love this lint remover! It quickly and effectively removes lint and pet hair from my clothes, leaving them looking brand new. The ergonomic handle makes it so easy to use, and its sleek design is a bonus. Highly recommend for anyone looking to keep their fabrics in top condition
        </p>
      </div>
      <div className="testimonial-section-bottom">
        <img src={ProfilePic} alt="" />
        <p>
          "This lint remover is a game-changer! It's lightweight, portable, and incredibly efficient. I use it on everything from sweaters to upholstery, and it works like a charm every time. The modern design is a nice touch, making it both functional and stylish. A must-have tool for every household!"
        </p>
        <div className="testimonials-stars-container">
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </div>
        <h2>John Doe</h2>
      </div>
    </div>
  );
};

export default Testimonial;
