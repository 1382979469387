// src/Components/MainLayout.js
import React from 'react';
import Navbar from './Navbar';

const MainLayout = ({ children }) => {
    return (
        <div className="App">
            <Navbar />
            <main>{children}</main>
        </div>
    );
};

export default MainLayout;
