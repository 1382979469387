import PaypalButtonBC from "./PaypalButtonBC";

const PaypalPaymentBC = () => {
    const containerStyle = {
        maxWidth: '300px',
        margin: '0 auto',
        textAlign: 'center',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',

    };

    return (
        <div style={containerStyle}>
            <PaypalButtonBC />
        </div>
    );
};

export default PaypalPaymentBC;
