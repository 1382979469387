import "./App.css";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
// import Work from "./Components/Work";
import Testimonial from "./Components/Testimonial";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import CreateDelivery from "./pages/CreateDelivery";
import Checkout from "./Components/Checkout/Checkout";
import CreditCardForm from "./Components/CreditCard/CreditCardForm.js";
import MainLayout from "./Components/MainLayout";
import PaypalPayment from "./Components/CreditCard/PaypalPayment";
import PaypalPaymentBC from "./Components/BetCenter/PaypalPaymentBC";

function App() {
    return (<Router>
            <Routes>
                <Route
                    path="/"
                    element={<MainLayout>
                        <Home/>
                        <About/>
                        {/*<Work />*/}
                        <Testimonial/>
                        <Contact/>
                        <Footer/>
                    </MainLayout>}
                />
                <Route
                    path="pages/create-delivery"
                    element={<MainLayout>
                        <CreateDelivery/>
                    </MainLayout>}
                />
                <Route
                    path="Components/Checkout/Checkout"
                    element={<MainLayout>
                        <Checkout/>
                    </MainLayout>}
                />
                <Route
                    path="/CreditCardForm/:user"
                    element={<CreditCardForm/>}
                />
                <Route
                    path="/PaypalPayment/:authToken/:user"
                    element={<PaypalPayment/>}
                />
                <Route
                    path="/PaypalPaymentBC/:user"
                    element={<PaypalPaymentBC/>}
                />
            </Routes>
        </Router>);
}

export default App;
