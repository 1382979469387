import React, { useState, useEffect } from 'react';
import config from "../config";
import {useParams} from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
import { ClipLoader } from 'react-spinners';


const PayPalButton = () => {



    const {user, authToken} = useParams();

    const [transactionSuccess, setTransactionSuccess] = useState(false);
    const [transactionFailed, setTransactionFailed] = useState(false);
    const [inputPrice, setInputPrice] = useState(''); // State to hold user input
    const [price, setPrice] = useState(null); // State to hold the final price

    const minDeposit = 50;

    // MUST BE NULL
    const [verifiedUser, setVerifiedUser] = useState(null);



    useEffect(() => {
        verifyUser()
    },[user]);

    useEffect(() => {
        if (price !== null) { // Ensure script only loads after price is set
            const script = document.createElement('script');
            //sandbox
            // script.src = `https://www.paypal.com/sdk/js?client-id=AZyktlkD2EPoYffARsKd7yy6pDyIUZQXcdxY7m0C_PzXdbYglaZubx-R-r8Kle4DqoHWDmzyqv2Y4ORD&locale=he_IL&currency=ILS&intent=capture`;
           //production
            script.src = `https://www.paypal.com/sdk/js?client-id=AeS3US0r-0Qjm95geIqzeB7BfzO7fN-UDkC226hqc3I7Wpx8wwqrtUIli2nE8QFuaoPCpIumu3ysJTmP&locale=he_IL&currency=ILS&intent=capture`;
            script.addEventListener('load', () => {
                window.paypal.Buttons({
                    createOrder: (data, actions) => {
                        return fetch(`${config.API_BASE_URL}/create_order`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                intent: 'CAPTURE',
                                amount: {
                                    'currency_code': 'ILS',
                                    'value': price
                                },
                                'custom_id': `${user}$$$${authToken}`
                            })
                        })
                            .then(response => response.json())
                            .then(order => order.id);
                    },
                    onApprove: (data, actions) => {
                        return fetch(`${config.API_BASE_URL}/complete_order`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                order_id: data.orderID,
                                intent: 'capture',
                                host: 'LO'
                            })
                        })
                            .then(response => response.json())
                            .then(orderDetails => {
                                const status = orderDetails.status;
                                status === "COMPLETED" ? setTransactionSuccess(true) : setTransactionFailed (true)
                            });
                    },
                    onError: err => {
                        console.error('PayPal error', err);
                    }
                }).render('#paypal-button-container');
            });
            document.body.appendChild(script);
        }
    }, [price]); // Dependency array with price

    const handleSubmit = (event) => {
        event.preventDefault();
        setPrice(inputPrice); // Set the price state
    };

    const verifyUser = async () => {
        try {
            console.log('user-> ', user);
            const res = await axios.get(`${config.API_BASE_URL}/whiteList/${user}`)
            res.data ? setVerifiedUser(true) : setVerifiedUser(false)
            console.log('user-> ', res.data);
        } catch (error) {
            console.error('Error making the request', error);
        }
    }

    // Handler to navigate the parent window
    const navigateToHomePage = () => {
        if (window.parent) {
            window.parent.location.href = config.HOST_IFRAME_URL;
        }
    };


    if (verifiedUser === true) {
        return (
            <div className="credit-card-paypal-container" style={styles.container}>
                {price === null ? ( // Render the input form if price is not set
                    <form onSubmit={handleSubmit} style={styles.form}>
                        <label style={styles.label}>
                            אנא הזן סכום
                            <br/>
                            <input
                                type="number"
                                value={inputPrice}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value < minDeposit) {
                                        e.target.setCustomValidity(`מינימום הפקדה  ${minDeposit}₪.`);
                                    } else {
                                        e.target.setCustomValidity("");
                                    }
                                    setInputPrice(value);
                                }}
                                required
                                min="50"
                                style={styles.input}
                            />
                        </label>
                        <button type="submit" style={styles.button}>הפקדה</button>
                    </form>
                ) : (
                    <div id="paypal-button-container"></div>
                )}
                {transactionSuccess && (<div className="popup">
                    <div className="popup-content">
                        <h2>הפקדה בוצעה בהצלחה</h2>
                        <button onClick={navigateToHomePage} className="buttonStyle">דף הבית</button>
                    </div>
                </div>)}
                {transactionFailed && (<div className="popup">
                    <div className="popup-content">
                        <h2>ההפקדה נכשלה</h2>
                        <button onClick={navigateToHomePage} className="buttonStyle">נסה שנית</button>
                    </div>
                </div>)}
            </div>

        );
    }
    if (verifiedUser === null) {
        return null;
    }

    return (
        <Box>
            <div>
                <h1>404 - Not Found</h1>
                <p>The page you are looking for does not exist.</p>
            </div>
        </Box>
    );
};

// Define styles as an object
const styles = {

    container: {
        backgroundColor: '#ffffff',
    },

    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // border: '1px solid #ddd',
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: '#fff',
        // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    label: {
        marginBottom: '10px',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#333',
    },
    input: {
        padding: '10px',
        fontSize: '16px',
        borderRadius: '4px',
        border: '1px solid #ddd',
        marginBottom: '10px',
        width: '200px',
        textAlign: 'center',
    },
    button: {
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '4px',
        border: 'none',
        backgroundColor: '#0070ba',
        color: '#fff',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    }
};

export default PayPalButton;
