
import React from 'react';

const createDelivery = () => {
    return (
        <div>
            <h1>Welcome to the New Page!</h1>
            <p>This is your new page content.</p>
        </div>
    );
};

export default createDelivery;
