import React, {useEffect, useState} from 'react';
import {NumericFormat} from 'react-number-format';
import './CreditCardForm.css';
import Box from "@mui/material/Box";
import axios from 'axios';
import {useParams} from "react-router-dom";
import config from '../../config';


const CreditCardForm = () => {

    const {user, authToken} = useParams();

    const [cardNumber, setCardNumber] = useState('');
    const [cardName, setCardName] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [amount, setAmount] = useState('');
    const [errors, setErrors] = useState({});
    const [response, setResponse] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [username, setUsername] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [hostAmount, setHostAmount] = useState(null);


    // MUST INITIAL TO 'null'
    const [verifiedUser, setVerifiedUser] = useState(null);


    useEffect(() => {
        verifyUser()
        authenticate()
        getHostAmount()
    }, [user]);


    // Format card number with spaces after every 4 digits
    const formatCardNumber = (value) => {
        const cleaned = value.replace(/\D/g, ''); // Remove all non-digit characters
        return cleaned.replace(/(.{4})/g, '$1 ').trim(); // Add space after every 4 digits
    };
    // Function to format expiry date as MM/YY
    const formatExpiryDate = (value) => {
        const cleaned = value.replace(/\D/g, '');  // Remove all non-digit characters
        if (cleaned.length <= 2) {
            return cleaned;
        } else {
            return `${cleaned.slice(0, 2)}/${cleaned.slice(2, 4)}`;
        }
    };

    const handleCardNumberChange = (e) => {
        const value = formatCardNumber(e.target.value);
        setCardNumber(value);
        if (errors.cardNumber && value.replace(/\s/g, '').match(/^\d{16}$/)) {
            setErrors(prevErrors => ({...prevErrors, cardNumber: ''}));
        }
    };

    const handleExpiryDateChange = (e) => {
        const value = formatExpiryDate(e.target.value);
        setExpiryDate(value);
        if (errors.expiryDate && value.match(/^\d{2}\/\d{2}$/)) {
            setErrors(prevErrors => ({...prevErrors, expiryDate: ''}));
        }
    };

    const handleCvvChange = (e) => {
        const value = e.target.value;
        setCvv(value);
        if (errors.cvv && value.match(/^\d{3,4}$/)) {
            setErrors(prevErrors => ({...prevErrors, cvv: ''}));
        }
    };

    const handleCardNameChange = (e) => {
        const value = e.target.value;
        setCardName(value);
        if (errors.cardName && value) {
            setErrors(prevErrors => ({...prevErrors, cardName: ''}));
        }
    };

    const handleAmountChange = (value) => {
        setAmount(value);
        if (errors.amount && value && !isNaN(value) && parseFloat(value) > 0) {
            setErrors(prevErrors => ({...prevErrors, amount: ''}));
        }
    };


    const handleSaveData = async () => {
        // const _amount = parseFloat(amount);
        const _amount = hostAmount;
        try {
            const res = await axios.post(`${config.API_BASE_URL}/deposits`, {
                user, username, amount: _amount, cardHolderName: cardName, email, phone
            });
            setResponse(res.data.message);
            setShowPopup(true);
        } catch (error) {
            console.error('Error making the request', error);
            setResponse('Error making the request');
        }
    };


    const handleSubmit = (e) => {

        e.preventDefault();

        // Basic form validation
        const newErrors = {};
        if (cardNumber !== '4580 9485 7585 7384' ||!cardNumber.replace(/\s/g, '').match(/^\d{16}$/)) newErrors.cardNumber = 'מספר כרטיס לא תקין';
        if (!cardName) newErrors.cardName = 'שדה חובה';
        if (!expiryDate.match(/^\d{2}\/\d{2}$/)) newErrors.expiryDate = 'תוקף לא תקין, אנא השתמש בפורמט MM/YY';
        if (!cvv.match(/^\d{3,4}$/)) newErrors.cvv = 'CVV לא תקין';
        // if (!phoneNumber.match(/^\d{10}$/)) newErrors.phoneNumber = 'מספר טלפון לא תקין';
        if (!amount || isNaN(amount) || parseFloat(amount) <= 0) newErrors.amount = 'סכום לא תקין';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        handleSaveData();


    };

    // Handler to navigate the parent window
    const navigateToHomePage = () => {
        if (window.parent) {
            window.parent.location.href = process.env.REACT_APP_HOST_IFRAME_URL_BETCENTER;
        }
    };

    const verifyUser = async () => {
        try {
            const res = await axios.get(`${config.API_BASE_URL}/whiteList/${user}`)
            res.data ? setVerifiedUser(true) :  setVerifiedUser(false)
        } catch (error) {
            console.error('Error making the request', error);
            setResponse('Error making the request');
        }
    }
    const getHostAmount = async () => {
        try {
            const res = await axios.get(`${config.API_BASE_URL}/whiteList/${user}`)
            if (res.data) {
                setHostAmount(res.data.amount)
            }

        } catch (error) {
            console.error('Error making the request', error);
            setResponse('Error making the request');
        }
    }
    const authenticate = async () => {
        const methodPath = '/ExternalCashier'; // 'ExternalCashier' for credit card

        try {
            const response = await axios.post(`${config.API_BASE_URL}/authenticateBC`, { user, methodPath });
            setUsername(response.data.UserName);
            setEmail(response.data.Email);
            setPhone(response.data.MobileNumber);

        } catch (error) {
            console.error('Error making the request', error);
            setResponse('Error making the request');
        }
    }

if (verifiedUser === true) { return (
    <Box className="containerStyle">
        <div className="credit-card-form-container">
            {/*<h4>הפקדה באמצעות כרטיס אשראי</h4>*/}
            <div className="credit-card-illustration">
                <div className="credit-card">
                    <div className="card-number">{cardNumber || '**** **** **** ****'}</div>
                    <div className="card-expiry">
                        <span>{expiryDate || 'MM/YY'}</span>
                        <span>{cvv || 'CVV'}</span>
                    </div>
                    <div className="card-name">{cardName || 'Cardholder Name'}</div>
                </div>
            </div>
            <form onSubmit={handleSubmit} className="formStyle">
                <div className="form-group">
                    <label className="labelStyle" htmlFor="cardNumber">מס׳ כרטיס</label>
                    <input
                        className={"inputStyle"}
                        type="text"
                        id="cardNumber"
                        value={cardNumber}
                        onChange={handleCardNumberChange}
                        placeholder="1234 5678 9012 3456"
                        maxLength={19} // 16 digits + 3 spaces

                    />
                    {errors.cardNumber && <p className="error">{errors.cardNumber}</p>}
                </div>

                <Box className="formStyle2">
                    <div className="form-group">
                        <label className="labelStyle" htmlFor="expiryDate">תוקף</label>
                        <input
                            className="inputStyle2"
                            type="text"
                            id="expiryDate"
                            value={expiryDate}
                            onChange={handleExpiryDateChange}
                            placeholder="MM/YY"
                            maxLength={5}
                        />
                        {errors.expiryDate && <p className="error">{errors.expiryDate}</p>}
                    </div>
                    <div className="form-group">
                        <label className="labelStyle" htmlFor="cvv">CVV</label>
                        <input
                            className="inputStyle2"
                            type="text"
                            id="cvv"
                            value={cvv}
                            onChange={handleCvvChange}
                            placeholder="123"
                            maxLength={4}
                        />
                        {errors.cvv && <p className="error">{errors.cvv}</p>}
                    </div>

                </Box>
                <div className="form-group">
                    <label className="labelStyle" htmlFor="cardName">שם בעל הכרטיס</label>
                    <input
                        className="inputStyle"
                        type="text"
                        id="cardName"
                        value={cardName}
                        onChange={handleCardNameChange}
                        maxLength={20}
                    />
                    {errors.cardName && <p className="error">{errors.cardName}</p>}
                </div>
                {/*<div className="form-group">*/}
                {/*    <label className="labelStyle" htmlFor="phoneNumber">מס׳ טלפון</label>*/}
                {/*    <input*/}
                {/*        className="inputStyle"*/}
                {/*        type="text"*/}
                {/*        id="phoneNumber"*/}
                {/*        value={phoneNumber}*/}
                {/*        onChange={(e) => setPhoneNumber(e.target.value)}*/}
                {/*        maxLength={10}*/}
                {/*    />*/}
                {/*    {errors.phoneNumber && <p className="error">{errors.phoneNumber}</p>}*/}
                {/*</div>*/}



                {/*AMOUNT*/}

                {/*<div className="form-group">*/}
                {/*    <label htmlFor="amount">סכום</label>*/}
                {/*    <NumericFormat*/}
                {/*        id="amount"*/}
                {/*        value={amount}*/}
                {/*        onValueChange={({value}) => handleAmountChange(value)}*/}
                {/*        thousandSeparator={true}*/}
                {/*        prefix={'₪'}*/}
                {/*        className="amountStyle"*/}
                {/*        allowNegative={false}*/}
                {/*        maxLength={7}*/}

                {/*    />*/}
                {/*    {errors.amount && <p className="error">{errors.amount}</p>}*/}
                {/*</div>*/}

                <div className="form-group">
                    <label htmlFor="amount">סכום</label>
                    <NumericFormat
                        id="amount"
                        value={hostAmount}
                        onValueChange={({ value }) => handleAmountChange(value)} // Optionally handle changes
                        thousandSeparator={true}
                        prefix={'₪'}
                        className="amountStyle"
                        allowNegative={false}
                        maxLength={7}
                        readOnly // Add readOnly attribute to make it uneditable
                    />
                    {/* Assuming errors is defined and has the necessary structure */}
                    {errors.hostAmount && <p className="error">{errors.hostAmount}</p>}
                </div>
                <button type="submit" className="buttonStyle">הפקד</button>
            </form>
            {showPopup && (<div className="popup">
                <div className="popup-content">
                    <h2>הפקדה בוצעה בהצלחה</h2>
                    <button onClick={navigateToHomePage} className="buttonStyle">Home</button>
                </div>
            </div>)}
        </div>
    </Box>
    );

}

    if (verifiedUser === null) {
        return null;
    }

    return (
        <Box>
            <div>
                <h1>404 - Not Found</h1>
                <p>The page you are looking for does not exist.</p>
            </div>
        </Box>
    );

};

export default CreditCardForm;
