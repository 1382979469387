import React from "react";
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/lint-remover.png";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";


const Home = () => {
  const navigate = useNavigate();


  return (
    <div className="home-container">
      {/*<Navbar />*/}
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">
            The New Lint Remover
          </h1>
          <p className="primary-text">
            Discover the ultimate solution to keep your fabrics looking fresh and new with our premium lint remover.
          </p>
          <button className="secondary-button" onClick={() => navigate("/Components/Checkout/Checkout")} >
            Order Now <FiArrowRight />{" "}
          </button>
        </div>
        <div className="home-image-section" >
          <img src={BannerImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
