import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/lint-remover2.png";
import { BsFillPlayCircleFill } from "react-icons/bs";

const About = () => {
  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">About</p>
        <h1 className="primary-heading">
          New Design
        </h1>
          <p className="primary-text">
              Our latest lint remover features an innovative design that combines functionality and style, making fabric care easier and more efficient than ever before.
          </p>
        <p className="primary-text">
            Designed to effortlessly eliminate lint, fuzz, and pet hair from all types of clothing and upholstery. Lightweight, durable, and easy to use, this handy tool ensures your garments stay pristine, enhancing their longevity and appearance.
        </p>

        <div className="about-buttons-container">
          <button className="secondary-button">Learn More</button>
          <button className="watch-video-button">
            <BsFillPlayCircleFill /> Watch Video
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
