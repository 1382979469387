import PayPalButton from "../PayPalButton";

const PaypalPayment = () => {
    const containerStyle = {
        maxWidth: '300px',
        margin: '0 auto',
        textAlign: 'center',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',

};

    return (
        <div style={containerStyle}>
            <PayPalButton />
        </div>
    );
};

export default PaypalPayment;
