// src/config.js

const config = {
    /** Switch for development/production **/

    /* DEVELOPMENT */
    // API_BASE_URL: process.env.REACT_APP_API_BASE_URL_DEVELOPMENT,
    // HOST_IFRAME_URL: process.env.REACT_APP_HOST_IFRAME_URL_DEVELOPMENT,

    /* PRODUCTION */
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL_PRODUCTION,
    HOST_IFRAME_URL: process.env.REACT_APP_HOST_IFRAME_URL_PRODUCTION,


    APP_NAME: 'Delivery APP',
    SUPPORT_EMAIL: 'support@example.com',
    API_KEY: process.env.REACT_APP_API_KEY,
};

export default config;
